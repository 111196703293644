@import '../../resources/media-queries.scss';

.program{
    &__banner {
        background:  url('/assets/chenar-photo.png');
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    height: 250px;
    }
    &__banner-wrapper{

    }
    &__anterior-programs{
        
    }
    &__title{
        color:#fff;
        text-align: left;
        font-size: 36px;
        padding-top: 50px;
        padding-left: 40px;
        z-index: 1;
    }
    &__banner-info{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        padding-left: 40px;

        align-items: center;
        img{
            width:180px;
            position: absolute;
            right:120px;
        }
        h3{
            color:#fff;
            font-size: 26px;
            padding-right: 10px;
            
        }
    }
    &__benefits{
        background:rgba(213, 215, 255, 0.6);
        padding:70px 20px 20px;
        margin:20px;
        border-radius: 14px;
        position:relative;
    }
    &__benefits-title{
        position:absolute;
        color:rgb(42, 38, 100);
        font-size: 36px;
        top:-30px;
        span{
            color:rgb(237, 19, 93)
        }
    }
    &__benefits-list{
        h4{
            text-align: left;
            padding:5px 0;
            color:rgb(42, 38, 100);
            font-size: 18px;
            font-weight: lighter;
            line-height: 1.4;
        }
    }
    &__packages{
        padding:40px 20px;
    }
    &__packages-title{
        font-size: 36px;
        color:rgb(42, 38, 100);
        text-align: center;

    }
    &__package{
        background-color: rgba(105, 117, 232, 1);
        border-radius: 15px;
        padding:20px;
        margin:15px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        h3, h4{
            color:#ffff;
            text-align: center;
        }
        h3{
            font-size: 26px;
            padding-top: 10px;
        }
    }
    &__pink-package{
        background-color: rgb(237, 19, 93);
    }
    &__form-title{
        text-align: left;
        font-size: 32px;
    }
    &__form-subtitle{
        text-align: left;
    }
    &__asterix{
        font-size: 16px;
        color: rgba(42, 38, 100, 1);
        text-align: center;
        font-weight: 500;
        line-height: 24px;
    }
    &__scholarship-title{
        text-align: left!important;
    }
    @include desktop{
        &__banner{
            height:450px;
            position: relative;
        }
        &__asterix{
            font-size: 22px;
        }
        &__title{
            z-index: 1;
            position: relative;
            font-size: 58px;
            line-height: 48px;
            padding-top: 150px;

        }
        &__title, &__banner-info{
            padding-left: 10%;
        }
        &__banner-info{
            h3{
                margin-top:0;
                position: relative;
                bottom:80px;
            }
            img{
                position:relative;
                width:270px;
                bottom:80px;
                right:20px;
                
            }
        }
        &__benefits{
            margin:40px 10%;
        }
        &__benefits-title{
            text-align: center;
            margin:0 auto;
            width:100%;
            top:-20px;
      
        }
        &__benefits-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding:0 10%;
        }
        &__benefits-list{
            width:47%;
            h4{
                font-size: 20px;
            }
        }
        &__packages-wrapper{
          display: flex;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          margin:0 auto;
          padding:40px 25%;
          
        }
        &__package{
            width:250px;
            height:50px;
            height:70px;
            display: flex;
            margin:0 auto;
            h4{
                font-size: 18px;
            }
            h3{
                font-size: 30px;
                padding-top: 15px;
            }
        }
     
    }
    &__pink-package{
        margin-right: 20px;
    }
    
}