@import '../../resources/media-queries.scss';

.article {
    &__container {}

    &__image-div {
        position: relative;
    }

    &__wrapper {
        padding: 20px;
        background-repeat: no-repeat;
    }

   
    &__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 23px;
        text-align: center;
        color: rgba(42, 38, 100, 1);
        padding-bottom: 20px;
    }

    &__first-paragraph {
        font-style: italic;
        text-align: center;
    }

    &__main-img,
    &__image {
        height: 200px;
        object-fit: cover;
        width:300px;
        border-radius: 10px;
        margin-top: 20px;
    }

    &__time-box {
        margin-top: 0;
    }

  
    &__image {
        max-width: 100%;
        display: block;
        margin: 10px auto;
    }

    &__paragraf {
        font-size: 14px;
        line-height: 30px;
        color: #000000;
        padding: 10px 0;
        text-align: left;
        text-indent: 10px;
    }

    &__subTitle {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        font-style: italic;
        line-height: 21px;
        color: #000000;
        padding: 10px 0;
    }

    &__social {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px auto 15px;
        width: 30%;

        img {
            width: 22px;
            cursor: pointer;
            height: 22px;

        }
    }

    &__social-bottom {
        padding-top: 20px;
    }

    &__subfooter {
        padding-bottom: 20px;
    }

    &__actions-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__action {
        display: flex;
        flex-direction: row;
        margin-top: 30px;

        img {
            width: 14px;
            height: 14px;
            margin: 0 5px;
            position: relative;
            top: 3px;
        }

        h3 {
            font-size: 16px;
            line-height: 18px;
            color: #646666;
            font-weight: lighter;
        }

        h3:hover {
            color: black;
        }
    }

    @include tablet {
        &__container {}
    }

    @include desktop {
        &__main-img, &__image{
            width:800px;
            height:500px;
            margin:40px 0;
            display: flex;
            padding-left: 10%;
        }
        &__wrapper{
            padding:40px 10%;
        }
        &__title{
            text-align: left;
        }
        &__paragraf{
            text-align: left;
            text-indent: 0;
            width:70%;
        }
        &__subTitle{
            text-align: left;
        }
        &__image{
            padding-left: 0;
        }
        &__social{
            width:10%;
            margin:20px 0 10px 0;
        }
        &__time-box{
            justify-content: left;
        }
        &__all-articles{
            padding:40px 10%;
        }
        &__articles-wrapper{
            display: flex;
            justify-content: space-between;
        }
        &__action{
            padding-left: 10%;
        }
    }
}