@import '../../resources/media-queries.scss';

.login{
    padding:0 20px 20px;
   
    min-height: 100vh;
    &__top{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:150px;
        z-index:1;
        display: inline-block;
        border:none;
        border-radius:0 0 50% 50%;    
        background-size:cover;
        transition: all .5s ease-in-out;
    }
    
    &__wrapper{
        padding:40px 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 123, 112, 0.2);
        margin-top: 10px;
        background-color: white;
    }
    &__logo{
        width:150px;
        height:auto;
        z-index:2;
        position:relative;
        object-fit: contain;
    }
    &__title{
        font-size: 20px;
    }
    &__subtitle{
        padding-top:10px;
        font-size: 14px;
        span{
            color:#007B70;
        }
    }
    &__submit-button{
        width: 88px;
        height: 38px;
        border-radius: 5px;
        background-color: #007B70;
        color:white;
        font-size: 16px;
        border:none;
        margin-top: 30px;
        width:100%;
    }
    &__next-button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img{
            width:16px;
            height:auto;
            margin-left: 10px;
            position:relative;
            top:2px;
        }
    }
    &__forgot-password{
        text-align: left;
        font-size: 14px;
        padding:3px 0;
        span{
            font-weight: bold;
            color:#007B70;
            cursor: pointer;
        }
    }
    &__label {
        font-family: Roboto;
        font-size: 12px;
        padding: 0 5px;
        z-index: 3;
        position: absolute;
        top: -7px;
        left: 15px;
    
        background-color: #ffff;
      }
    
      input:-internal-autofill-selected {
        background-color: white !important;
      }
      &__field-box {
        position: relative;
        margin: 20px 0;
      }
    
      &__field {
        border-radius: 4px;
        border: solid 1px #79747E;
        height: 48px;
        padding: 6px 0 0;
        text-indent: 10px;
        width: 100%;
      }
      &__password-input{
        position: relative;
      }
      &__eye-icon{
        width:18px;
        height:auto;
        position: absolute;
        left:95%;
        top:20px;
        cursor: pointer;
      }
    @include tablet{

    }
    @include desktop{
        &__logo{
            width:220px;
        }
        &__top{
            height:200px;
        }
        &__wrapper{
            width:30%;
            margin:40px auto;
            padding:40px 5%;
        }
    }
}