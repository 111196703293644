@import '../../resources/media-queries.scss';

.faq-section {
    position: relative;
}

.faq-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.faq-item {
    text-align: left;
    position: relative;

}
.qa-icon{
    position: absolute;
    width:100px;
    z-index:1;
}
.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    color: rgba(42, 38, 100, 1);
    font-size: 16px;
}



.faq-icon {
    width: 12px;
    height: auto;
    object-fit: contain;
}

.faq-answer {
    padding: 10px;
    animation: fadeIn 0.3s ease;
    color: rgba(42, 38, 100, 1);
    font-size: 14px;

}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@include desktop{
    .faq-question{
      justify-content: initial;
    }
    .faq-icon{
        margin-left: 20px;
    }
}