@import '../../resources/media-queries.scss';

.header {
    display: none;

    @include desktop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 70%;
        z-index: 5;
        min-width: 700px;
    }

    &__item {
        color: rgb(42, 38, 100);
        font-size: 16px;
        line-height: 30px;
        cursor: pointer;
    }
    &__submenu {
        position: absolute;
        top: 30px;
        left: 0;   
        background: white; 
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); 
        z-index: 100; 
        padding: 10px; 
        width:160px;
        z-index: 12;

        h5 {
            color: rgb(42, 38, 100);
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            text-align: center;
            margin: 0; /* Avoid margin issues */
            transition: border 0.3s ease;
        }

        h5:hover {
            border-bottom: 1px solid rgb(42, 38, 100);
            cursor: pointer;
        }
    }
    &__menu-icon{
        width:12px;
        padding-left: 4px;
    }
    &__item-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        position:relative;
        cursor: 'pointer';
    }
    &__social{
        display: flex;
        flex-direction: row;
        align-items: center;
        img{
            width:22px;
            margin-right: 5px;
            height:22px;
        }
    }
}