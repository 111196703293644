@import '../../resources/media-queries.scss';

.journalism{
    &__banner{
        background: linear-gradient(90deg, #6F7CFF 28.28%, rgba(214, 101, 133, 0.9) 52.33%, rgba(214, 101, 133, 0) 78.5%),url('/assets/journalism.jpg');

       }
       &__logo{
        width:100px!important;
        right:170px;
       }
    @include desktop{
        &__banner-info{
            display: flex;
            flex-direction: row;
            padding-left: 10%;
            margin-top: 90px;
            h3{
                color: #fff;
                font-size: 34px;
                padding-right: 10px;
            }
        }
        &__logo{
            width:200px!important;
            height:80px;
            position: relative;
            bottom:20px;
            margin-left: 20px;
        }
    }
}