@import '../../resources/media-queries.scss';

.form{

    &__wrapper{
        display: flex;
        flex-direction: column;
        margin: 0 0 20px 0;
        padding: 0 20px 20px 20px;
    }
    input, &__select, &__textarea {
        border: 1px solid rgba(42, 38, 100, 1);
        border-radius: 10px;
        background-color: #fff;
        height: 46px;
        margin: 5px 0;

    }

    input:focus, &__select:focus, &__textarea:focus {
        outline: none;
    }

    input::placeholder {
        text-indent: 3px;
        font-weight: 500;
    }
    &__textarea{
        height:auto;
    }
    &__box{
        display: flex;
        flex-direction: column;
        margin:20px 0;
    }
    &__label{
        font-family: "Avenir", sans-serif;
        text-align: left;
        color:rgb(38, 37, 37);
        font-size: 14px;

    }
    &__checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
        margin: 10px 0;


    }
    &__checkbox-box{
        display: flex;
        flex-direction: row;
        font-family: "Avenir", sans-serif;
        font-size: 14px;
        align-items: center;
    }
    .form__checkbox-container {
        display: flex;
        flex-wrap: wrap;
    }

    &__checkbox {
        width: 45%;
        margin: 8px;
        display: flex;
        align-items: center;
        h5{
            position: relative;
            top:2px;
        }
    }


    @include desktop{
        &__wrapper{
            padding: 10px 40% 40px 10%;
        }
        &__inputs-box{
            display: flex;
            flex-direction: row;
            width:100%;
            justify-content: space-between;
        }
        &__box{
            width:47%;
        }
        .form__checkbox-container {
          width:100%;
        }
        &__checkbox{
            width:30%;
        }
        &__label{
            margin-bottom:5px;
            height:25px;
        }
        &__submit{
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.hp {
  &__about {
      padding: 20px;
  }

  &__section-title {
      color: rgba(42, 38, 100, 1);
      font-size: 36px;
      padding-bottom: 15px;
      text-align: left;

      span {
          color: rgba(237, 19, 93, 1);

      }
  }

  @include desktop {
    &__about {
        padding: 10% 10% 0;
        padding-top: 10%;
        width: 40%;
    }
  }
}
