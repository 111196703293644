@import '../../resources/media-queries.scss';

.fashion {
    &__banner {
        background: linear-gradient(90deg, #ED135D 0%, rgba(188, 60, 156, 0.794132) 32.5%, rgba(111, 124, 255, 0.47) 56%, rgba(214, 101, 133, 0) 100%), url('/assets/fashion.jpg');
        ;

    }
    &__yellow-font{
       color: rgba(251, 169, 35, 1)!important;
    }

    @include desktop {
        &__banner-info {
            display: flex;
            flex-direction: row;
            padding-left: 10%;
            margin-top: 30px;

            h3 {
                color: #fff;
                font-size: 34px;
                padding-right: 10px;
            }
        }

        &__logo {
            width: 200px;
            height: 80px;
            position: relative;
            bottom: 20px;
        }
    }
}