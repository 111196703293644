@import '../../resources/media-queries.scss';

.anterior {
    &__banner {
        background: linear-gradient(90deg, #FF5C54 27.28%, rgba(214, 101, 133, 0.716046) 51%, rgba(111, 124, 255, 0) 79.13%),
            url('/assets/anterior-programs.jpg');
        background-repeat: no-repeat;
        background-position: center;
    }

    &__custom-select {
        position: relative;
        width: 300px;
        border:none;
        display: flex;
        flex-direction: row;
        padding:20px;
    }

    &__custom-select select {
        appearance: none;
        /* Remove default arrow */
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
        padding: 8px 12px;
        font-size: 16px;
        border-radius: 4px;
        color: rgba(42, 38, 100, 1);
        font-weight: bold;
        font-family: "Avenir", sans-serif;
        cursor: pointer;
        border:none;
    }

    &__custom-select select:focus {
        outline: none;
        border: none;
    }
    &__down{
        width:20px;
        height:auto;
        object-fit: contain;
    }
    &__mentor-list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &__mentor-card{
        width:45%;
        background-color: rgba(251, 169, 35, 1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__mentor-image{
        width:80%;

    }
    &__mentor-name{
        
    }
    &__mentor-role{

    }
    &__actions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:20px;
    }
    &__action-button{
        width:100px;
        height:30px;
        border-radius: 7px;
        border:none;
        color:#fff;
        font-family: "Avenir", sans-serif;
        font-size: 10px;

    }
    &__agend{
        background: rgba(237, 19, 93, 1);
    }
    &__galery{
        background: rgba(105, 117, 232, 1);

    }
    &__testimonials{
        background: rgba(254, 212, 64, 1);
        color:black;
    }
    &__mentors-visual{
        width:80%;
        margin:20px auto;
        border-radius: 10px;
    }

   

    @include desktop {
        &__mentors-visual{
            width:60%;
            margin-top: 40px;
        }
        &__wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding:40px 10%;
        }
        &__actions{
            flex-direction: column;
        }
        &__action-button{
            width:180px;
            height:50px;
            margin-bottom: 40px;
            font-size: 14px;
        }
        &__left-section{
            text-align: left;
            h3{
                color: rgba(42, 38, 100, 1);
                font-weight: bold;
                padding-bottom: 40px;
            }
            h4{
                color: rgba(42, 38, 100, 1);
                font-weight: lighter;
                border-bottom: 1px solid rgba(42, 38, 100, 1);;
            }
        }
        &__center-section{
            h3{
                color: rgba(42, 38, 100, 1);
                font-weight: bold;
            }
        }
    }
}