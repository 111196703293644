@import '../../resources/media-queries.scss';

.pr{
   &__pro{
    display: flex;
    flex-direction: column;
    h5{
        padding:5px 0;
    }
   }
   &__yellow{
    color:rgb(251, 169, 35) !important
   }
   &__section-description{
    font-weight: lighter;
   }
   &__pink{
    font-weight: bold;
    color: rgba(237, 19, 93, 1);

   }
   &__banner{
    background: linear-gradient(90deg, #ED135D 0%, rgba(81, 88, 190, 0.8) 40.5%, rgba(111, 124, 255, 0.47) 57.33%, rgba(255, 92, 84, 0) 71.5%), url('/assets/PR.jpg');

    background-repeat: no-repeat;
    background-size: cover;
   }
   &__info-pic{
    width:80%;
    padding:20px;
   }
   &__soon{
    text-align: center;
    color:rgb(42, 38, 100);
    font-size: 18px;
   }
   &__package{
    background-color: rgba(105, 117, 232, 1);
   
   }

    @include tablet{

    }
    @include desktop{
        &__pro{
            h4{
                font-size: 16px;
            }
            h5{
                font-size: 14px;
                font-weight: lighter;
            }
        }
        &__pro-title{
            width:100%!important;
        }
        &__package{
            margin:0 auto 20px;
            width:250px;
            height:50px;
        }
        &__button{
            display: flex;
            margin:20px auto!important;
        }
        &__info-pic{
            object-fit: contain;
        }
        &__pro-last-left{
            margin-left: 10%;
        }
        &__pro-last-right{
            margin-right: 20%;
        }
        &__scolarship-title{
            text-align: left;
        }
        &__benefit{
            width:25%;
            padding:20px;
        }
        &__mentor{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width:30%;
            button{
                margin-left: 0;
            }
        }
        &__mentor-image{
           
        }
    }   
}