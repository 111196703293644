@import '../../resources/media-queries.scss';

.attachment-form {
  &__upload-files {
    border: none; 
    background-color: rgba(183, 225, 250, 0.3);
    height: auto;
    margin: 10px 0;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  &__instruction {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }

  &__upload {
    font-size: 16px;
    line-height: 1.6;
    color: #130f36;
  }

  &__file-list {
    list-style-type: none;
  }

  &__existing-files {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0d0d0d;
    font-weight: 500;
  }

  &__upload-button {
    background: #8C52FF;
    border: none;
    margin: 20px auto;
    border-radius: 5px;
    color: #fff;
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-weight: bold;
    
    h5 {
      font-size: 14px;
      font-weight: lighter;
      padding-right: 5px;
    }
  }

  &__icon {
    width: 36px;
    height: 36px;
    display: flex;
    margin: 10px auto;
  }

  &__add {
    font-family: "Raleway", serif;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin: 10px auto 0 !important;
  }

  &__upload-icon {
    width: 20px;
    height: 20px;
  }

  &__delete-button {
    border: none;
    border-radius: 3px;
    background-color: #cc0000;
    color: #fff;
    padding: 5px 10px;
    width: 100px;
    position:relative;
    top:0;
  }

  &__preview {
    margin-top: 10px;
    text-align: center;
  }

  &__image-preview {
    max-width: 100%;
    height: 350px;
    object-fit: contain;
  }

  @include desktop {
    &__upload-button {
      width: 250px !important;
      margin: 20px 0 10px !important;
    }
    
    &__delete-button {
      margin-left: 10%;
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}

.drop-instruction {
  display: flex;
  flex-direction: row;
}