@import '../../resources/media-queries.scss';

.testimonials {
    &__container {
        padding: 20px;
    }

    &__single {
        border: 2px solid rgba(42, 38, 100, 1);
        padding: 20px;
        margin: 20px 0;
        text-align: left;
        border-radius: 10px;
    }

    &__name {}

    &__grade {
        padding: 10px 0;
    }

    &__text {
        color: rgb(42, 38, 100);
        padding-bottom: 10px;
        line-height: 24px;
    }

    @include desktop {

        &__left-section {
            width: 30%;
        }

        &__central-section {
            width: 70%;
        }

        &__container {
            column-count: 3; // Change the number based on your layout
            column-gap: 20px; // Space between columns
            padding: 40px;
        }
        
        &__single {
            display: inline-block; // Important for masonry effect
            width: 80%; // Make sure it takes full width in its column
            margin-bottom: 20px; // Prevent elements from touching
            border: 2px solid rgba(42, 38, 100, 1);
            padding: 20px;
            text-align: left;
            border-radius: 10px;
        }
        &__lighter{
            font-weight: lighter;
        }
        &__title{
            text-align: left;
            padding-left: 5%;
        }
        

        &__text {
            flex-grow: 1;
        }
    }
}