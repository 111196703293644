@import '../../resources/media-queries.scss';

    .hamburger{
        width:50%;
        display: flex;
        justify-content: flex-end;
        
        &__icon{
            width:12px;
            height:12px;
            position: relative;
            top: 5px;
            cursor: pointer;
            border:1px solid rgba(91, 95, 199, 1);
            border-radius: 8px;
            padding:10px;
        }
       
        &__close-icon {
            display: none;
            position: absolute;
            width: 10px;
            right: 3%;
            top: 16px;
            right:15px;
            z-index: 20;
            border-radius: 4px;
            padding:5px;
        
            &.active {
              display: block;
            }
          }
          &__links-list{
            margin-top: 80px;
            z-index: 20;
            position: relative;
          }
        &__links {
            list-style: none;
            width: 100%;
            float: right;
            margin-right: 0;
            z-index: 20;
            clear: both;
            overflow: hidden;
            top: 0;
            right: 0;
            padding: 20px 0 0 80px;
            height: 100%;
            margin-top: 0;
            background: rgba(42, 38, 100, 1);
            position: fixed;
            transform: translateX(100%);
            transition: transform .3s;
            overflow-y: auto;
    
        
            &.active {
              transform: translateX(0);
            }
        }
        &__list-item{
            text-align: center;
            line-height: 1.24;
            margin-top: 60px;
            color: #fff;
            padding:10px;
        }
        &__link--menu{
            font-size: 16px;
            font-weight: bolder;
            margin: 10px 0;
            padding-left: 8px;
        }
        &__link{
            text-decoration: none;
            color:initial;
        }
        &__platform-access{
            border:none;
            border-radius: 5px;
            background-color: #ffffff;
            height:46px;
            display: flex;
            justify-content: center;
            margin:20px auto;
        }
        &__platform{
            color: var(--Webpix-Black, rgba(34, 34, 34, 1));
            font-weight: 700;
            font-size: 16px;
            border:2px solid rgba(91, 95, 199, 1);
            border-radius: 100px;
            width:200px;
            height:48px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:10px auto;
            align-self: center;
            align-content: center;
            
        }
   
     
    
        @include desktop{
            width:initial;
            &__icon{
                display: none;
            }
        }
    }