.slick-arrow {
    width: 30px; 
    height: 30px; 
    background-size: contain;
    background-color: #fff;
    z-index: 2;
    width:48px;
    height:48px;
    border-radius: 50%;
    background-size: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 4;
}

.slick-prev {
    left: 25px; 
    top:97%;

}

.slick-next {
    right: 100px;
    top:96%;
}